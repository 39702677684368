<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="预约演示" @moTitltFn="navPanel"></MoTitle>
    <div class="moShowContainer contentBgColor center">
        <div class="moShowTitle">请完善以下信息，我们将尽快与您联系</div>
        <div class="moCooForm center">
              <van-form @submit="onSubmit" :colon="true" label-width="70px" style="width:100%; background: transparent;z-index:10" ref="form">
              <van-field
                  v-model="form.NAME"
                  name="姓名"
                  label="姓名"
                  required
                  :rules="[{ required: true, message: '请填写姓名' }]"
                  style=" background: transparent;"
              />
              <van-field
                  v-model="form.MOBILE"
                  name="手机号"
                  label="手机号"
                  required
                  :rules="[{ required: true, message: '请填写手机号' },{pattern: /^1[3|4|5|7|8][0-9]{9}$/, message: '手机号格式不正确'}]"
                  style=" background: transparent;"
              />
              <!-- <van-field
                  v-model="form.MAILBOX"
                  name="邮箱"
                  label="邮箱"
                  style=" background: transparent;"
              /> -->
              <van-field
                  v-model="form.DEPT"
                  required
                  :rules="[{ required: true, message: '请填写单位' }]"
                  name="单位"
                  label="单位"
                  style=" background: transparent;"
              />

                <div class="formBottom">
                    <van-button  class="formSbumit" native-type="submit" style="color:#fff">提交</van-button>
                    <span class="formTip">您提交的信息将会受到严格保密</span>
                </div>
              </van-form>
        </div>
        <img src="@/assets/moSubmitBg.png" alt="" class="bottomImg">
    </div>
    <el-dialog title="预约演示" :visible.sync="dialogVisible" width="90%" :before-close="()=>{dialogVisible = false}">
      <div style="text-align:center;padding:20px 0 60px">
        <div class="el-icon-circle-check" style="font-size:105px;color:#00C952"></div>
        <div style="font-size:20px;color:#222;margin-top:13px;margin-bottom:10px">提交成功</div>
        <div style="font-size:16px;color:#666">感谢您的信任与支持，我们将尽快与您取得联系</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import { submitForm } from '@/require/index.js'
export default {
  name: 'moShow',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      form: {
        NAME: '',
        MOBILE: '',
        // MAILBOX: '',
        DEPT: ''
      },
      dialogVisible: false
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    onSubmit () {
    //   console.log(this.form)
      const { NAME, MOBILE, DEPT } = { ...this.form }

      submitForm(NAME, MOBILE, DEPT).then(res => {
        if (res) {
          this.form.NAME = ''
          this.form.MOBILE = ''
          this.form.DEPT = ''
          this.dialogVisible = true
        }
      }).catch(err => {
        this.$message.error(err.msg ? err.msg : err)
      })
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/freeTrial')
      }
    }
  },
  created () {
    // console.log(this.$route.query)
    // if (this.$route.query) {
    //   this.form.MAILBOX = this.$route.query.MAILBOX
    // }
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
 background: #F5F5F5;;
  z-index: 10;
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/Cooperation/Partnership/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moShowContainer{
  padding-top: 2.75rem;
  height: 100vh;
  box-sizing: border-box;
}
.moShowTitle{
font-size: 1rem;
font-weight: 500;
color: #323233;
margin-top: 1.875rem;
margin-bottom: 1.25rem;

}
.moCooForm{
    width: 100%;
    z-index:10;
}

v-deep .van-cell{
  background: transparent;
}
v-deep .van-cell__title span{
font-size: 150%;
}

::v-deep .van-field__label span{
   margin-left: .9375rem;
   font-size: 1rem;
   color: #222;
}
::v-deep .van-cell{
    height: 4.125rem;
    padding-top: 1.25rem;
}
::v-deep  .van-cell:last-child::after{
    display: block;
}
.formSbumit{
    width:98%;
height: 2.875rem;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
border-radius: .25rem;
border: 0;
color: #fff;
margin-top: 1.25rem;
}
.formTip{
font-size: .75rem;
font-weight: 400;
color: #999999;
margin: 1.25rem 0;
}
.van-button__text{
    color: #fff;
}
.formBottom{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomImg{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
</style>
